/* used to define the routes for custom router navigations inside pages and components */
/* eslint-disable @typescript-eslint/naming-convention */
export const AppPages = {
  AccountDetailPage: '/tabs/account',
  AccountDevicePage: '/tabs/account/device',
  AccountEditPage: '/tabs/account/edit',
  AccountPasswordPage: '/tabs/account/password',
  AccountPermissionsPage: '/tabs/account/permissions',
  AccountSettingsPage: '/tabs/account/settings',
  ConsentPage: '/consent',
  ContactsAddPage: '/tabs/contacts/add',
  ContactsDetailPage: '/tabs/contacts/${id}',
  ContactsListPage: '/tabs/contacts',
  CreditsAddPage: '/tabs/credits/add',
  CreditsSuccessPage: '/tabs/credits/success',
  DashboardPage: '/tabs/dashboard',
  DeveloperPage: '/developer',
  GuardEmergencyPage: '/tabs/guard/${id}/emergency',
  GuardInprogressPage: '/tabs/guard/${id}',
  GuardStartPage: '/tabs/guard',
  GuardStoppedPage: '/tabs/guard/${id}/stopped',
  ImportContactsPage: '/import',
  IntroPage: '/intro',
  LoginPage: '/login',
  LostPwPage: '/lostpw',
  PurchasesListPage: '/tabs/account/purchases',
  RegisterPage: '/register',
  SessionsDetailPage: '/tabs/sessions/${id}',
  SessionsListPage: '/tabs/sessions',
  SetPwPage: '/setpw',
  SetupCompletePage: '/setup/complete',
  SetupContactsPage: '/setup/contacts',
  SetupDeviceConnectPage: '/setup/device/connect',
  SetupDeviceHelpPage: '/setup/device/help',
  SetupDeviceResultPage: '/setup/device/result',
  SetupDeviceSearchPage: '/setup/device/search',
  SetupDeviceTestPage: '/setup/device/test',
  SetupDisclaimerPage: '/setup/disclaimer',
  SetupPermissionsPage: '/setup/permissions',
  SetupTutorialContentPage: '/setup/tutorial/content',
  SetupTutorialStartPage: '/setup/tutorial/start',
  SetupWelcomePage: '/setup/welcome',
  SupportPage: '/tabs/account/support',
  VerifyEmailPage: '/verify-email'
};
